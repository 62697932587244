import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppRoutingModule } from './app-routing.module';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { Jwtinterceptor } from './interceptor/jwt.interceptor';
import { ShellMainComponent, ShellModule } from './shell';

@NgModule({
    imports: [BrowserModule, AppRoutingModule, ShellModule, HttpClientModule, KeycloakAngularModule, FormsModule, ReactiveFormsModule],
    bootstrap: [ShellMainComponent],
    declarations: [],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        { provide: HTTP_INTERCEPTORS, useClass: Jwtinterceptor, multi: true },
    ],
})
export class AppModule {}
