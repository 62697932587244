import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import { UserService } from '../../users/services/users.service';
import { IAlfrescoDocumentData } from '../interfaces/alfresco.interface';
import { IDocumentSearch } from '../interfaces/documents.interface';

@Injectable({
    providedIn: 'root',
})
export class DocumentService extends BaseService {
    constructor(public httpClient: HttpClient, public userService: UserService) {
        super(httpClient, 'document');
    }

    uploadFile(fileToUpload: File, alfrescoId: string, reasonChange: string): Observable<IAlfrescoDocumentData> {
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload);
        formData.append('alfrescoId', alfrescoId);
        formData.append('reason', reasonChange);

        return this.httpClient.post<IAlfrescoDocumentData>(environment.baseUrl + this.collection + '/upload', formData);
    }

    getHistorical(docId: number) {
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/version-history/${docId}`, {});
    }

    getPrivateDocs() {
        let currentUserId: number = this.userService.currentUser.id;
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/private/${currentUserId}`, {});
    }

    getMyDocs() {
        let currentUserId: number = this.userService.currentUser.id;
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/myDocs/${currentUserId}`, {});
    }

    search(docSearch: IDocumentSearch) {
        let currentUserId: number = this.userService.currentUser.id;
        return this.httpClient.post(`${environment.baseUrl}${this.collection}/search/user/${currentUserId}`, docSearch);
    }

    getPreFilterDocs(servantId: number) {
        let currentUserId: number = this.userService.currentUser.id;
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/servant/${servantId}/user/${currentUserId}`, {});
    }

    getInteractedDocs() {
        let currentUserId: number = this.userService.currentUser.id;
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/interacted/${currentUserId}`, {});
    }

    addToInteractors(docId: number) {
        let currentUserId: number = this.userService.currentUser.id;
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/addInteractor/doc/${docId}/user/${currentUserId}`, {});
    }

    getAlfrescoDocVersion(alfrescoId: string) {
        let formatId = alfrescoId.split(';')[0];
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/version/${formatId}`, {});
    }

    getAlfrescoInlineDocumentById(alfrescoId: string) {
        let formatId = alfrescoId.split(';')[0];
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/inline/${formatId}`, { responseType: 'blob' });
    }

    getAlfrescoDocumentById(alfrescoId: string) {
        let formatId = alfrescoId.split(';')[0];
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/download/${formatId}`, {});
    }

    toStringImpl(docId: number) {
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/toString/${docId}`, {});
    }
}
