import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from '../modules/users/services/users.service';

@Injectable()
export class Jwtinterceptor implements HttpInterceptor {
    constructor(protected readonly userService: UserService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const jwtToken = this.userService.jwtToken;

        if (!environment.jwtEnabled || !jwtToken) {
            return next.handle(request);
        }

        const req1 = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${jwtToken}`),
        });

        return next.handle(req1);
    }
}
