import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import { PolicesService } from '../../policies/polices.service';
import { USER_ROLES } from '../constants/roles.const';
import { IUser, IUserOption } from '../interfaces/users.interface';
import { ServoRoleService } from './servo.role.service';

const collectionName = 'person';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService {
    currentUser: IUser;
    jwtToken: String;

    constructor(
        public httpClient: HttpClient,
        private keycloakService: KeycloakService,
        private servoRoleService: ServoRoleService,
        private policesService: PolicesService
    ) {
        super(httpClient, collectionName);
    }

    async refreshCurrentUser() {
        this.currentUser = null;
        await this.checkCurrentUserExists();
    }
    async checkCurrentUserExists() {
        if (!this.currentUser) {
            let aux;
            // not load
            try {
                aux = await this.keycloakService.loadUserProfile();
            } catch (err) {
                console.log('err', err);
            }

            if (aux) {
                this.currentUser = (await this.getUserByName(aux.username).toPromise()) as IUser;
                if (!this.jwtToken) {
                    this.jwtToken = await this.keycloakService.getToken();
                    console.log('SET JWT_TOKEN', this.jwtToken);
                }

                let roles = (await this.servoRoleService.getRoles(aux.username).toPromise()) as any;
                if (!this.currentUser) {
                    // user not exists
                    let u: IUserOption = {
                        id: 0,
                        keycloakId: aux.id,
                        email: aux.email,
                        name: aux.username,
                        roles: roles,
                        enabled: true,
                    };

                    this.currentUser = (await this.save(u).toPromise()) as IUser;
                } else {
                    this.currentUser.lastConnection = new Date();
                    this.currentUser.keycloakId = aux.id;
                    this.currentUser.roles = roles;
                    this.currentUser = (await this.save(this.currentUser).toPromise()) as IUser;
                }
            } else {
                this.logout();
            }
        }
        await this.policesService.loadPolices();
    }

    private checkRole(role: string) {
        return this.currentUser.roles && this.currentUser.roles.includes(role);
    }

    hasLastPolicyApproved() {
        return (
            this.currentUser.approvedVersion != null &&
            this.currentUser.approvedVersion.length &&
            this.currentUser.approvedVersion == this.policesService.privatePolices.version
        );
    }

    async updatetPolicyApproved() {
        this.currentUser.approvedVersion = this.policesService.privatePolices.version;
        await this.save(this.currentUser).toPromise();
        this.refreshCurrentUser();
    }

    isReader() {
        return this.checkRole(USER_ROLES.GDLAB_LECTOR);
    }

    isAdmin() {
        return this.checkRole(USER_ROLES.GDLAB_ADMIN);
    }

    isAuthor() {
        return this.checkRole(USER_ROLES.GDLAB_AUTOR);
    }

    isManager() {
        return this.checkRole(USER_ROLES.GDLAB_GESTOR);
    }

    isReviewer() {
        return this.checkRole(USER_ROLES.GDLAB_REVISOR);
    }

    isAccepter() {
        return this.checkRole(USER_ROLES.GDLAB_APROBADOR);
    }

    logout() {
        return this.keycloakService.logout(window.location.origin);
    }

    public uploadImage(userId: number, image: File) {
        return this.httpClient.post(environment.baseUrl + `${collectionName}/uploadImage`, {
            file: image,
            userId: userId,
        });
    }

    getUserByEmail(email: string) {
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/email/${email}`, {});
    }

    getUserByName(name: string) {
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/name/${name}`, {});
    }

    getUsersByServant(servantId: number) {
        return this.httpClient.get(`${environment.baseUrl}${this.collection}/servant/${servantId}`, {});
    }

    getUsersByArea(areaId: number) {
        return this.httpClient.get(environment.baseUrl + this.collection + '/area/' + areaId, {});
    }

    getUsersByDevice(deviceId: number) {
        return this.httpClient.get(environment.baseUrl + this.collection + '/device/' + deviceId, {});
    }

    getUsersByProof(proofId: number) {
        return this.httpClient.get(environment.baseUrl + this.collection + '/proof/' + proofId, {});
    }

    getUsersByTask(taskId: number) {
        return this.httpClient.get(environment.baseUrl + this.collection + '/task/' + taskId, {});
    }

    getEnabled() {
        return this.httpClient.get(environment.baseUrl + this.collection + '/enabled');
    }
}
